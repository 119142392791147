.create-app-form {
  max-width: 300px;
  margin-top: 2em;
}

.input-error {
  background: #fff;
  border-color: #ff4d4f !important;
}

.invalid-value-block {
  margin-top: .5rem;
  color: #ff4d4f;
}